import React from "react";
import style from './index.module.less'
import {
    beamIntro1,
    beamIntro2,
    beamIntro3, powerVideo
} from '@/images/beam/index'
import {
    beamApp_video,
    beamAppInstall_video
} from '@/images/air2/index'
import { useIntl } from "react-intl";

const MoreThan = ({ ifShow }) => {
    const intl = useIntl()

    const introArr = [
        {
            img: beamIntro1,
            desc: intl.formatMessage({ id: "beam.morethan.intro.text1" }),
            tip: " "
        },
        {
            img: beamIntro2,
            desc: intl.formatMessage({ id: "beam.morethan.intro.text2" }),
            tip: ""
        },
        {
            img: beamIntro3,
            desc: intl.formatMessage({ id: "beam.morethan.intro.text3" }),
            tip: ""
        }
    ]
    const textArr = [
        `1. ${intl.formatMessage({ id: "beam.morethan.intro.tip1" })}`,
        `2. ${intl.formatMessage({ id: "beam.morethan.intro.tip2" })}`,
        `3. ${intl.formatMessage({ id: "beam.morethan.intro.tip3" })}`,
        `4. ${intl.formatMessage({ id: "beam.morethan.intro.tip4" })}`
    ]
    const videoList = [
        {
            title: intl.formatMessage({ id: "beam.morethan.video1.title" }),
            desc: intl.formatMessage({ id: "beam.morethan.video1.desc" }),
            tip: ""
        },
        {
            title: intl.formatMessage({ id: "beam.morethan.videoo2.title" }),
            desc: intl.formatMessage({ id: "beam.morethan.videoo2.desc" }),
            tip: intl.formatMessage({ id: "beam.morethan.videoo2.tip" })
        }
    ]
    return (
        <div className={style.morethan}>
            <h2 className={style.morethanTitle}>{intl.formatMessage({ id: "beam.morethan.title" })}</h2>
            <div className={style.morethanDesc}>
                {intl.formatMessage({ id: "beam.morethan.desc" })}
            </div>
            <div style={{ padding: "0 20px" }}>
                <div className={style.morethanVideo}>
                    {ifShow && <video autoPlay playsInline loop muted width="100%" height='100%' style={{ objectFit: "cover" }}>
                        <source src={beamApp_video} type="video/mp4" />
                    </video>}
                </div>
            </div>



            <div className={style.morethanIntro}>
                <div className={style.morethanIntroFlex}>
                    {introArr.map((item, index) => (
                        <div className={style.morethanIntroFlexItem} key={`morethanIntro${index}`}>
                            <img src={item.img} />
                            <div className={style.morethanIntroFlexItemDesc}>{item.desc}</div>
                            <div className={style.morethanIntroFlexItemTip}>{item.tip}</div>
                        </div>
                    ))}
                </div>
                <div className={style.morethanIntroText}>
                    {
                        textArr.map((item, index) => (
                            <p key={`morethanIntroText${index}`} dangerouslySetInnerHTML={{ __html: item }}></p>
                        ))
                    }
                </div>
            </div>
            <div className={style.content}>
                <div className={style.contentBox}>
                    <div className={style.contentBoxVideo}>
                        <div className={style.contentBoxVideoBg} >
                            {ifShow && <video autoPlay playsInline loop muted width="100%" >
                                <source src={beamAppInstall_video} type="video/mp4" />
                            </video>}

                        </div>
                    </div>
                    <div className={style.contentBoxIntro}>
                        {videoList.map((item, index) => (
                            <div className={style.contentBoxIntro_box} key={`morethanVideo${index}`}>
                                <div className={style.contentBoxIntro_boxTitle}>{item.title}</div>
                                <div className={style.contentBoxIntro_boxText} >{item.desc}</div>
                                <div className={style.contentBoxIntro_boxTip}>{item.tip}</div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoreThan