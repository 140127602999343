import React  from 'react'
import style from './index.module.less'
import { wired_beam } from '@/images/beam/index'
import { useIntl } from "react-intl"
import { Parallax } from '@/components/index'
import useBigScreen from "@/hooks/bigScreen"

const WiredBox = ({ifShow}) => {
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    return (
        <div className={style.wired}>
            <h2 className={style.wiredTitle}>{intl.formatMessage({ id: "beam.connection.t" })}</h2>
            <div className={style.wiredDesc}>{intl.formatMessage({ id: "beam.connection.comments" })}</div>
            <Parallax background={ifShow?wired_beam:""} speed={ifPc?200:100} styles={{width:"100%",height:ifPc?'100vh':"auto",aspectRatio:ifPc?'none':'16/9'}}/>

        </div>

    )
}

export default WiredBox