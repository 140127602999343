import React, { useEffect, useRef , useState } from "react";
import style from './index.module.less'
import { beamPro, beamProText } from '@/images/beam/index'
import classNames from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import {
    supportBeamVideo1,
    supportBeamVideo1_jp,
    supportBeamVideoPic,
    playBtn
} from '@/images/support/index'



const Details = () => {
    const intl = useIntl()
    gsap.registerPlugin(ScrollTrigger)
    const beamImgRef = useRef(null)
    const videoRef = useRef(null)
    const [videoPlay , setVideoPlay]=useState(false)
    const [ifDrag , setIfDrag] = useState(false)
    const bigScreen = useBigScreen(768)
    useEffect(() => {
        let detailTween = gsap.timeline({
            scrollTrigger: {
                trigger: beamImgRef.current,
                start: "top center",
                end: 'bottom top',
                toggleActions: 'play reverse play reverse',
            }
        })
        detailTween.from('.powerline', { width: 0, duration: 0.5 })
            .to('.powerText', { opacity: 1 }, '>')
            .to('.ledline div', { left: 0, duration: 0.5 }, 0)
            .to('.ledText', { opacity: 1 }, '>')
            .to('.switchline div', { left: 0, duration: 0.5 }, 0)
            .to('.switchText', { opacity: 1 }, '>')
            .from('.okline1', { width: 0, duration: 0.5 }, 0)
            .from('.okline2', { height: 0, duration: 0.5 }, ">")
            .from('.okline', { width: 0, duration: 0.5 }, ">")
            .to('.okText', { opacity: 1 }, ">")
            .from('.volumeline', { width: 0, duration: 0.5 }, 0)
            .to('.volumeText', { opacity: 1 }, ">")
            .from('.directionline1', { width: 0, duration: 0.5 }, 0)
            .from('.directionline2', { height: 0, duration: 0.5 }, ">")
            .from('.directionline', { width: 0, duration: 0.5 }, ">")
            .to('.directionText', { opacity: 1 }, ">")
            .to('.homeline div', { left: 0, duration: 0.5 }, 0)
            .to('.homeText', { opacity: 1 }, ">")
            .from('.inline1', { height: 0, duration: 0.5 }, 0)
            .to('.inline div', { left: 0, duration: 0.5 }, ">")
            .to('.inText', { opacity: 1 }, ">")
            .from('.outline1', { height: 0, duration: 0.5 }, 0)
            .from('.outline', { width: 0, duration: 0.5 }, ">")
            .to('.outText', { opacity: 1 }, ">")


    }, [])

    const playVideo = ()=>{
        setVideoPlay(true)
        videoRef.current.play()
    }
    return (
        <>
            <div className={style.video}>
                <h2 className={style.title}> {intl.formatMessage({ id: "beam_video_title", defaultMessage: "XREAL Beam Tutorial" })}</h2>
                <div className={style.videoBox}  >
                {!videoPlay&& <img style={{opacity:videoPlay?0:1}} src={playBtn} className={style.playIcon} onClick={()=>playVideo()} />} 
                    <video 
                    autoPlay={false}
                    width="100%" 
                    controls={videoPlay} 
                    playsInline 
                    poster={supportBeamVideoPic}
                    ref={videoRef}
                    >
                        <source src={intl.locale=='ja'?supportBeamVideo1_jp:supportBeamVideo1} type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className={style.detail}>
                <h2 className={style.title}>
                    {intl.formatMessage({ id: "beam_detail", defaultMessage: " Product Details" })}
                </h2>
                {bigScreen ? <div className={style.pro}>
                    <div className={style.proImg}>
                        <img src={beamPro} ref={beamImgRef} />
                        <div className={style.power}>
                            <div className={classNames(style.line, 'powerline')}>
                            </div>
                            <div className={classNames(style.detailText, 'powerText')}>{intl.formatMessage({ id: "beam_detail_power", defaultMessage: "Power" })}</div>
                        </div>
                        <div className={style.led}>
                            <div className={classNames(style.line, 'ledline')}>
                                <div></div>
                            </div>
                            <div className={classNames(style.detailText, 'ledText')}>{intl.formatMessage({ id: "beam_detail_led", defaultMessage: "LED Light" })}</div>
                        </div>
                        <div className={style.switch}>
                            <div className={classNames('switchline', style.line)}><div></div></div>
                            <div className={classNames(style.detailText, 'switchText')}>{intl.formatMessage({ id: "beam_detail_switch", defaultMessage: "Mode Switch" })}</div>
                        </div>
                        <div className={style.home}>
                            <div className={classNames(style.line, 'homeline')}><div></div></div>
                            <div className={classNames(style.detailText, 'homeText')}>
                                {intl.formatMessage({ id: "beam_detail_back", defaultMessage: "Back" })}
                                <div>
                                    {intl.formatMessage({ id: "beam_detail_home", defaultMessage: "Home" })}<span> {intl.formatMessage({ id: "beam_detail_home_tip", defaultMessage: "(Lone Press)" })}</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.volume}>
                            <div className={classNames(style.line, 'volumeline')}></div>
                            <div className={classNames(style.detailText, 'volumeText')}>{intl.formatMessage({ id: "beam_detail_volume", defaultMessage: "Volume" })}</div>
                        </div>
                        <div className={style.ok}>
                            <div className={classNames(style.line1, 'okline1')}></div>
                            <div className={classNames(style.line2, 'okline2')}></div>
                            <div className={classNames(style.line, 'okline')}></div>

                            <div className={classNames(style.detailText, 'okText')}>{intl.formatMessage({ id: "beam_detail_ok", defaultMessage: "OK" })}</div>
                        </div>
                        <div className={style.direction}>
                            <div className={classNames(style.line1, 'directionline1')}></div>
                            <div className={classNames(style.line2, 'directionline2')}></div>
                            <div className={classNames(style.line, 'directionline')}></div>

                            <div className={classNames(style.detailText, 'directionText')}>{intl.formatMessage({ id: "beam_detail_direction", defaultMessage: "Direction" })}</div>
                        </div>
                        <div className={style.in}>
                            <div className={classNames(style.line1, 'inline1')}></div>

                            <div className={classNames(style.line, 'inline')}><div></div></div>
                            <div className={classNames(style.detailText, 'inText')}>{intl.formatMessage({ id: "beam_detail_usbIn", defaultMessage: "UCB-C IN" })}</div>
                        </div>
                        <div className={style.out}>
                            <div className={classNames(style.line1, 'outline1')}></div>

                            <div className={classNames(style.line, 'outline')}></div>
                            <div className={classNames(style.detailText, 'outText')}>{intl.formatMessage({ id: "beam_detail_usbOut", defaultMessage: "UCB-C OUT" })}
                                <span>{intl.formatMessage({ id: "beam_detail_usbOut_tip", defaultMessage: "(Connect to Glasses)" })}</span>
                            </div>
                        </div>
                    </div>


                </div> : <img src={beamProText} className={style.proImgText} />}

            </div>
        </>

    )
}

export default Details