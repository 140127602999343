import React from 'react'
import style from './index.module.less'
import {
    PowerHalf,
    PowerFull,
    powerVideo
} from '@/images/beam/index'
import { useIntl } from "react-intl"

const Power = ({ifShow}) => {
    const intl = useIntl()
    const powerList = [
        {
            img: <PowerHalf />,
            desc: intl.formatMessage({ id: "beam_plug_1", defaultMessage: "<span>3.5 hours</span> of battery life, ultra-long standby time of more than <span>7 days</span>" })
        },
        {
            img: <PowerFull />,
            desc: intl.formatMessage({ id: "beam_plug_3", defaultMessage: "Charge to <span>50%</span> in <span>30 minutes</span>, support <span>27w</span> fast charge" })
        }
    ]
    return (
        <div className={style.power}>
            <h2 className={style.title}>{intl.formatMessage({ id: "beam_plug_title", defaultMessage: "Just Plug in the Glasses" })}</h2>
            {/* <div className={style.desc}>{intl.formatMessage({ id: "beam_plug_desc", defaultMessage: "Automatic power on and standby" })}</div> */}
            <div className={style.content}>
                <div className={style.contentBox}>
                    <div className={style.contentBoxVideo}>
                        <div className={style.contentBoxVideoBg} >
                            {ifShow&& <video autoPlay playsInline loop muted width="100%" >
                                <source src={powerVideo} type="video/mp4" />
                            </video>}
                           
                        </div>
                    </div>
                    <div className={style.contentBoxIntro}>
                        {powerList.map((item, index) => (
                            <div className={style.contentBoxIntro_box} key={`power${index}`}>
                                {item.img}
                                <div className={style.contentBoxIntro_boxText} dangerouslySetInnerHTML={{ __html: `${item.desc}` }}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Power